
import * as ipxStaticRuntime$hPLw1NWRLA from '/opt/build/repo/node_modules/.pnpm/@nuxt+image-edge@1.0.0-rc.3-28277678.46975fe/node_modules/@nuxt/image-edge/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "blockproof.fr"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$hPLw1NWRLA, defaults: undefined }
}
        